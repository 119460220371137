import { GearListFilters, MenuItem } from 'types';

import cx from 'classnames';
import styles from './GearFilterPanel.module.scss';

export type CategoriesFilterProps = {
  markets: MenuItem[];
  appliedFilters: GearListFilters;
  onApply: (categories: Object) => void;
};

export const MarketsFilter = ({ markets = [], appliedFilters, onApply }: CategoriesFilterProps) => {
  const handleSelectMarket = (slug: string) => {
    const newFilters = { ...appliedFilters };
    delete newFilters.sizes;
    delete newFilters.category;

    newFilters['markets'] = [slug];
    onApply(newFilters);
  };

  const renderMarkets = () => {
    return markets.map((market) => {
      return (
        <div key={market.id} className={styles.categoriesContainer}>
          <p
            onClick={() => handleSelectMarket(market.slug)}
            className={cx(styles.categoryText, {
              [styles.active]: market.slug === appliedFilters.markets?.[0],
            })}
          >
            {market.title}
          </p>
        </div>
      );
    });
  };

  return <div className={styles.categoriesFilter}>{renderMarkets()}</div>;
};
