import { useEffect, useState } from 'react';
import { MenuCategoryItem, GearListFilters, GearFilterCategory } from 'types';
import { sortByTextField } from 'utils/services';
import { SearchInput } from '../SearchInput';

import cx from 'classnames';
import styles from './GearFilterPanel.module.scss';

export type CategoriesFilterProps = {
  appliedFilters: GearListFilters;
  categories: GearFilterCategory[];
  highPriorityCategories: GearFilterCategory[];
  onApply: (categories: Object) => void;
};

const filterByTitleAndChildren = (items: GearFilterCategory[], query: string): GearFilterCategory[] => {
  return items.filter((item) => {
    if (item.title.toLowerCase().includes(query.toLowerCase())) {
      return true;
    }
    if (item.children?.length) {
      const nestedResults = filterByTitleAndChildren(item.children, query);
      item.children = nestedResults;
      return nestedResults.length;
    }
    return false;
  });
};

export const CategoriesFilter = ({
  categories = [],
  highPriorityCategories = [],
  appliedFilters,
  onApply,
}: CategoriesFilterProps) => {
  const [hasShowMore, setHasShowMore] = useState(!!highPriorityCategories.length && !!categories.length);
  const initialCategories = highPriorityCategories.length ? highPriorityCategories : categories;
  const [baseCategories, setBaseCategories] = useState(initialCategories);
  const [currentCategories, setCurrentCategories] = useState(initialCategories);
  const [search, setSearch] = useState('');
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setShowMore(!highPriorityCategories.length);
  }, []);

  useEffect(() => {
    changeCategoriesDisplay();
  }, [showMore]);

  useEffect(() => {
    changeCategoriesDisplay();
  }, [initialCategories]);

  const changeCategoriesDisplay = () => {
    if (showMore || !highPriorityCategories.length) {
      setBaseCategories(sortByTextField(categories));
      setCurrentCategories(sortByTextField(categories));
      setHasShowMore(false);
    } else {
      setBaseCategories(highPriorityCategories);
      setCurrentCategories(highPriorityCategories);
      setHasShowMore(true);
    }

    setSearch(' ');
    setTimeout(() => setSearch(''), 0);
  }

  const handleSelectCategory = (slug: string | undefined) => {
    const newFilters = { ...appliedFilters };
    delete newFilters.sizes;
    if (slug) {
      newFilters.category = slug;
    } else {
      delete newFilters.category;
    }
    onApply(newFilters);
  };

  const getAppliedCategoryBloodLine = (categories: MenuCategoryItem[], parents: string[] = []) => {
    if (!appliedFilters.category) {
      return [];
    }

    for (const category of categories) {
      if (category.slug === appliedFilters.category) {
        return [...parents, category.slug];
      }

      if (category.children.length) {
        const bloodline: string[] = getAppliedCategoryBloodLine(category.children, [...parents, category.slug]);
        if (bloodline.length) {
          return bloodline;
        }
      }
    }
    return [];
  };

  const onSearch = (query: string) => {
    setCurrentCategories(filterByTitleAndChildren([...baseCategories], query));
  };

  const renderCategories = (categories: MenuCategoryItem[], bloodline: string[] = []) => (
    <div className={styles.categoriesChildren}>
      {categories.map((cat) => {
        return (
          <div key={cat.id} className={styles.categoriesContainer}>
            <p
              onClick={() => handleSelectCategory(cat.slug)}
              className={cx(styles.categoryText, {
                [styles.active]: cat.slug === appliedFilters.category && bloodline.length === 1,
              })}
            >
              {cat.title}
            </p>
            {!!cat.children.length &&
              !!bloodline.length &&
              bloodline[0] === cat.slug &&
              renderCategories(cat.children, bloodline.slice(1))}
          </div>
        );
      })}
    </div>
  );

  const onShowMore = () => {
    setShowMore(true);
  };

  const onShowLess = () => {
    setShowMore(false);
  };

  return (
    <div className={styles.categoriesFilter}>
      <SearchInput onSearch={onSearch} className={styles.categoriesSearch} placeholder="Search by category" value={search} />
      {renderCategories(currentCategories, getAppliedCategoryBloodLine(currentCategories))}
      {hasShowMore && (
        <p className={styles.showMore} onClick={onShowMore}>
          Show More
        </p>
      )}
      {!!highPriorityCategories.length && !hasShowMore && (
        <p className={styles.showMore} onClick={onShowLess}>
          Show Less
        </p>
      )}
    </div>
  );
};
