import { ColorItem } from 'types';

import cx from 'classnames';
import styles from './GearFilterPanel.module.scss';

export type ColorsFilterProps = {
  colors: ColorItem[];
  value: number[];
  onChange: (newValue: number[]) => void;
};

export const ColorsFilter = ({ colors = [], value, onChange }: ColorsFilterProps) => {
  const handleSelect = (color: number) => {
    const index = value.indexOf(color);

    if (index < 0) {
      onChange([...value, color]);
    } else {
      const newValue = value.slice();
      newValue.splice(index, 1);
      onChange(newValue);
    }
  };

  return (
    <div>
      <div className={styles.colorsFilter}>
        {colors.map((color) => (
          <div
            key={color.id}
            className={cx(styles.colorsFilterItem, {
              [styles.active]: value.indexOf(+color.id) >= 0,
            })}
            onClick={() => handleSelect(+color.id)}
          >
            <div style={{ backgroundColor: color.value }} />
          </div>
        ))}
      </div>
    </div>
  );
};
