import styles from './ItemImageNotes.module.scss'

export type ItemImageNotesProps = {
texting?: string;
withCover?: boolean;
};

export const ItemImageNotes = ({texting = '', withCover}: ItemImageNotesProps) => {
  return (
    <div className={styles.container}>
      {withCover && <div className={styles.cover}/>}
      <p>{texting}</p>
    </div>
  );
};
