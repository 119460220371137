import { useEffect, useState } from 'react';
import { CurrentUser } from 'types';
import { useRouter } from 'next/router';
import { Button } from 'components/Button';
import { Snackbar, SnackbarProps } from 'components/Snackbar';
import { HorizontalDivider } from 'components/HorizontalDivider';
import {
  checkIfFullyAuthorized,
  getLinkForGearShare,
  handleCopyToClipboard,
  handleShare,
  toAuthAndBack
} from 'utils/services';

import styles from './MyGear.module.scss';

type ShareModalProps = {
  dividerText?: string;
  isGearOwner?: boolean;
  isSold?: boolean;
  shareToFollowersBtn?: boolean;
  slug: string;
  user?: CurrentUser | undefined;
};

export const ShareModal = ({
  dividerText,
  isGearOwner = false,
  isSold = false,
  shareToFollowersBtn,
  user,
  slug,
}: ShareModalProps) => {
  const router = useRouter();

  const [referenceUrl, setReferenceUrl] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarProps | null>(null);

  const hostname = window.location.hostname;

  useEffect(() => {
    setReferenceUrl(getLinkForGearShare(hostname, slug));
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  const copyToClipboard = (value: string) => {
    handleCopyToClipboard(value);
    setSnackbarMessage({ header: 'Successfully copied' });
  };

  const redirectToAuth = () => toAuthAndBack(window.location.pathname, router, user);

  const onShare = (slug: string) => handleShare(slug, setSnackbarMessage);

  const handleShareClick = (slug: string) => {
    if (checkIfFullyAuthorized(user)) {
      onShare(slug).then();
    } else {
      redirectToAuth();
    }
  };

  return (
    <>
      {shareToFollowersBtn && !isGearOwner && !isSold && (
        <Button
          action={() => handleShareClick(slug)}
          buttonType="blue"
          caption="To my followers"
          iconSrc="/icons/communications/ShareNetworkLight.svg"
        />
      )}

      {dividerText && !isGearOwner && !isSold && <HorizontalDivider caption={dividerText} />}

      <div className={styles.modalButtons}>
        <Button
          buttonType="darkBlue"
          fit={false}
          iconSrc="/icons/brands/FacebookLogoLight.svg"
          caption="Facebook"
          action={() => {
            FB.ui(
              {
                display: 'popup',
                method: 'share',
                hashtag: 'SENDY',
                href: referenceUrl,
              },
              (response: any) => {
                console.log(response?.error_message);
              },
            );
          }}
        />
        <Button
          buttonType="gray"
          fit={false}
          iconSrc="/icons/system/LinkLight.svg"
          caption="Copy link"
          action={() => copyToClipboard(getLinkForGearShare(hostname, slug))}
        />
      </div>

      {snackbarMessage && <Snackbar {...snackbarMessage} onClose={() => setSnackbarMessage(null)} />}
    </>
  );
};
