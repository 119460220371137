import {SimpleItem, SimpleItemWithSlug} from "types";
import styles from './GearCard.module.scss';

export type ProductSizesWithBrandsProps = {
  sizes: SimpleItem[];
  brand: SimpleItemWithSlug;
}

export const GearSizesWithBrands = ({sizes, brand}: ProductSizesWithBrandsProps) => {
  return (
    <div className={styles.productSizes}>
      {!!sizes.length && (
        <>
          <p>
            Size: <span>{sizes.map((size) => size.title).join(' ')}</span>
          </p>
          <div className={styles.verticalDivider}>
            &nbsp;|&nbsp;
          </div>
        </>
      )}
      <p>
        Brand: <span>{brand.title}</span>
      </p>
    </div>
  );
};
