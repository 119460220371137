import { useState, useEffect } from 'react';
import Image from 'next/legacy/image';

import { AllGearFilters, GearListFilters, MenuCategoryItem } from 'types';

import cx from 'classnames';
import styles from './GearFilterPanel.module.scss';

export type AppliedFiltersProps = {
  allFilters?: AllGearFilters;
  appliedFilters: GearListFilters;
  onRemove: (values: Object) => void;
  isHome?: boolean;
};

export type AppliedFilter = {
  id: string | number | null;
  field: string;
  title: string;
  idField?: string;
};

const recursiveFindCategory = (categories: MenuCategoryItem[], slug: string): MenuCategoryItem | null => {
  for (const category of categories) {
    if (category.slug === slug) {
      return category;
    }

    if (category.children) {
      const found = recursiveFindCategory(category.children, slug);
      if (found) {
        return found;
      }
    }
  }

  return null;
};

const filterAppliedFilters = (
  filtersList: AppliedFilter[],
  options: any[] = [],
  field: string,
  value: any,
  idField = 'id',
  titleField = 'title',
) => {
  if (!Array.isArray(value)) {
    value = [value];
  }

  options.forEach((option) => {
    const index = value.indexOf(option[idField]);
    if (index >= 0) {
      filtersList.push({
        id: value[index],
        field,
        title: option[titleField],
        idField,
      });
    }
  });
};

export const AppliedFilters = ({ allFilters = {} as AllGearFilters, appliedFilters, onRemove, isHome }: AppliedFiltersProps) => {
  const [appliedFiltersList, setAppliedFiltersList] = useState<AppliedFilter[]>([]);

  useEffect(() => {
    setAppliedFilters();
  }, [appliedFilters, allFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearAllFilters = () => {
    onRemove({status: 'active', country: appliedFilters.country});
  };

  const setAppliedFilters = () => {
    const filtersList: AppliedFilter[] = [];

    let market: any = null;
    let category: any = null;

    Object.entries(appliedFilters).forEach(([field, value]) => {
      if (!value || (Array.isArray(value) && !value.length)) {
        return;
      }

      switch (field) {
        case 'markets':
          market = value;
          return;
        case 'category':
          category = value;
          return;
        case 'tag':
          return;
        case 'price_min':
          filtersList.push({
            id: null,
            field: field,
            title: `From $${value}`,
          });
          break;
        case 'price_max':
          filtersList.push({
            id: null,
            field: field,
            title: `To $${value}`,
          });
          break;
        case 'state':
        case 'city':
        case 'q':
          filtersList.push({
            id: null,
            field: field,
            title: value as string,
          });
          break;
        case 'brands':
        case 'activities':
          filterAppliedFilters(filtersList, allFilters[field], field, value, 'slug');
          break;
        default:
          // @ts-ignore
          if (!!allFilters[field] && Array.isArray(allFilters[field])) {
            // @ts-ignore
            filterAppliedFilters(filtersList, allFilters[field], field, value);
          }
      }
    });

    if (market) {
      market = allFilters.markets?.find((m) => m.slug === market[0]);

      if (market) {
        filtersList.unshift({
          id: market.slug,
          field: 'markets',
          title: market.title,
        });
      }
    }

    if (category && allFilters.categories) {
      const targetCategory = recursiveFindCategory(allFilters.categories, category);
      if (targetCategory) {
        filtersList.unshift({
          id: targetCategory.slug,
          field: 'category',
          title: targetCategory.title,
        });
      }
    }

    setAppliedFiltersList(filtersList);
  };

  const handleRemove = (item: AppliedFilter) => {
    // @ts-ignore
    const currentVal = appliedFilters[item.field];
    const updatedFilters: any = { ...appliedFilters };

    if (Array.isArray(currentVal)) {
      const index = currentVal.indexOf(item.id);
      if (index >= 0) {
        currentVal.splice(index, 1);
        if (currentVal.length) {
          updatedFilters[item.field] = currentVal;
        } else {
          delete updatedFilters[item.field];
        }
      }
    } else {
      delete updatedFilters[item.field];
    }

    if (item.field === 'markets') {
      delete updatedFilters.category;
    }

    if (item.field === 'markets' || item.field === 'category') {
      delete updatedFilters.sizes;
    }

    onRemove(updatedFilters);
  };

  if(isHome && appliedFiltersList.length === 0)
    return null

  return (
    <div className={cx(styles.filter, styles.filtersList)}>
      {appliedFiltersList.length > 1 && (
        <div className={styles.filtersListItem}>
          <p className={styles.title}>Clear All</p>
          <div className={styles.icon} onClick={clearAllFilters}>
            <Image src="/icons/development/XGrey.svg" alt="" width={16} height={16} />
          </div>
        </div>
      )}
      {appliedFiltersList.map((item) => (
        <div key={`${item.field}_${item.id}`} className={styles.filtersListItem}>
          <p className={styles.title}>{item.title}</p>
          <div className={styles.icon} onClick={() => handleRemove(item)}>
            <Image src="/icons/development/XGrey.svg" alt="" width={16} height={16} />
          </div>
        </div>
      ))}
    </div>
  );
};
