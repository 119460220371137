import { FocusEvent, ChangeEvent, useState, useEffect } from 'react';
import { COUNTRY_OPTIONS, PROVINCE_OPTIONS, STATE_OPTIONS } from 'consts';
import { Dropdown } from 'components/Dropdown';

import styles from './GearFilterPanel.module.scss';

export type LocationFilterProps = {
  country?: string;
  state?: string;
  city?: string;
  onChange: (values: Object) => void;
};

export const LocationFilter = ({ country, state, city, onChange }: LocationFilterProps) => {
  const [cityVal, setCityVal] = useState<string>('');
  const [currentCountry, setCurrentCountry] = useState<string>(country || '');
  const [stateOptions, setStateOptions] = useState(STATE_OPTIONS);

  useEffect(() => {
    setCityVal(city || '');
  }, [city]);

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const val = String(e.target.value);

    if ((city || '') !== val) {
      onChange({
        city: val,
      });
    }
  };

  const handleSelectState = (fieldName: string, value: any) => {
    if (value !== state) {
      onChange({
        state: value,
      });
    }
  };

  const handleSelectCountry = (fieldName: string, value: any) => {
    if (currentCountry !== value) {
      setCurrentCountry(value);
      setStateOptions(value === 'CAN' || value === 'CA' ? PROVINCE_OPTIONS : STATE_OPTIONS);
      onChange({
        state: '',
        country: value,
      });
    }
  };

  const handleChangeCity = (e: ChangeEvent<HTMLInputElement>) => {
    setCityVal(e.target.value);
  };

  return (
    <div className={styles.locationFilter}>
      <Dropdown
        value={currentCountry}
        options={COUNTRY_OPTIONS}
        placeholder="Country"
        onChange={handleSelectCountry}
        isThin={true}
      />
      <Dropdown
        value={state}
        options={stateOptions}
        placeholder="State/Province"
        onChange={handleSelectState}
        isThin={true}
      />
      <input
        type="text"
        className={styles.cityInput}
        placeholder="City"
        value={cityVal}
        onChange={handleChangeCity}
        onBlur={(e) => handleBlur(e)}
      />
    </div>
  );
};
