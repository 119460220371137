import { ReactElement, memo } from 'react';
import Image from 'next/legacy/image';

import cx from 'classnames';
import styles from './GearFilterPanel.module.scss';

export type FilterBlockProps = {
  title: string;
  children: ReactElement;
  isCollapsed?: boolean;
  onCollapsed?: () => void;
  withDropdown?: boolean;
};

export const FilterWrapper = memo(function renderFilterWrapper({
  title,
  children,
  isCollapsed,
  onCollapsed,
  withDropdown = false,
}: FilterBlockProps) {
  return (
    <div className={cx(styles.filter)}>
      <div
        className={cx(styles.header, {
          [styles.collapsed]: !isCollapsed,
        })}
        onClick={onCollapsed}
      >
        <p>{title}</p>
        <div className={styles.headerIcon}>
          <Image src="/icons/arrows/CaretDown.svg" alt="" width={24} height={24} />
        </div>
      </div>
      <div
        className={cx(styles.body, {
          [styles.collapsed]: isCollapsed,
          [styles.withDropdown]: withDropdown,
        })}
      >
        {children}
      </div>
    </div>
  );
});
