import { useState, useEffect } from 'react';
import { NextIcon } from '../NextImage';

import styles from './GearSortDropdown.module.scss';

type SortFields = 'posted_at' | 'price';
type SortOrders = 'asc' | 'desc';

export type SortData = {
  sort: SortFields;
  order: SortOrders;
};

export type SortDropdownProps = SortData & {
  onChange: (sorting: SortData) => void;
};

type SortOption = {
  label: string;
  sort: SortFields;
  order: SortOrders;
};

const options: SortOption[] = [
  { label: 'Newest', sort: 'posted_at', order: 'desc' },
  { label: 'Oldest', sort: 'posted_at', order: 'asc' },
  { label: 'Low Price', sort: 'price', order: 'asc' },
  { label: 'High Price', sort: 'price', order: 'desc' },
];

export const GearSortDropdown = ({ sort, order, onChange }: SortDropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<SortOption>();
  const [isOpened, setIsOpened] = useState<boolean>(false);

  useEffect(() => {
    setSelectedOption(options.find((o) => o.sort === sort && o.order === order));
  }, [sort, order]);

  const handleDocumentClick = () => {
    setIsOpened(false);
    document.removeEventListener('click', handleDocumentClick);
  };

  const handleOpen = () => {
    if (!isOpened) {
      setIsOpened(true);
      setTimeout(() => {
        document.addEventListener('click', handleDocumentClick);
      });
    }
  };

  const handleSelect = (option: SortOption) => {
    onChange({
      sort: option.sort,
      order: option.order,
    });
  };

  return (
    <div className={styles.listSort}>
      <div className={styles.listSortLabel}>Sort by:</div>
      <div className={styles.listSortDropdown}>
        <div className={styles.listSortDropdownValue} onClick={handleOpen}>
          <p>{selectedOption?.label || 'Select'}</p>
          <div className={styles.listSortDropdownValueIcon}>
            <NextIcon src={isOpened ? '/icons/arrows/CaretUpDark.svg' : '/icons/arrows/CaretDownDark.svg'} />
          </div>
        </div>
        {isOpened && (
          <div className={styles.listSortDropdownOptions}>
            {options.map((option) => (
              <div
                key={option.label}
                className={styles.listSortDropdownOptionsItem}
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
