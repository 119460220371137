import { CommonCustomerItem, DealTypeForTab } from 'types';
import { Brands } from './Brands';

import cx from 'classnames';
import styles from './Brands.module.scss';

export type BrandType = 'common' | 'halfed' | 'inline' | 'inlineFeed';

export type BrandsListProps = {
  activeItem?: string;
  className?: string;
  customThumbnailClass?: string;
  leadTo?: 'brands' | 'activities';
  onClick?: (slug: string) => void;
  tab?: DealTypeForTab;
  brands: Array<CommonCustomerItem>;
  type?: BrandType;
  withOpacity?: boolean;
  withTitle?: boolean;
};

export const BrandsList = ({
                                activeItem,
  className,
  customThumbnailClass,
  leadTo,
  onClick,
  tab,
  brands,
  type = 'common',
  withOpacity = true,
  withTitle,
}: BrandsListProps) => {
  return (
    <div
      className={cx(
        styles.brandsList,
        {
          [styles.commonList]: type === 'common',
          [styles.halfedList]: type === 'halfed',
          [styles.inlineList]: type === 'inline',
          [styles.inlineFeedList]: type === 'inlineFeed',
        },
        className,
      )}
    >
      {brands.map((item: CommonCustomerItem) => (
        <Brands
          key={item.id}
          isActiveItem={activeItem === item.slug}
          customClass={customThumbnailClass}
          filter={leadTo}
          onClick={onClick}
          tab={tab}
          brand={item}
          type={type}
          withOpacity={withOpacity}
          withTitle={withTitle}
        />
      ))}
    </div>
  );
};
