import styles from './HorizontalDivider.module.scss';

export const HorizontalDivider = ({caption}: {caption?: string}) => {
  return (
    <div className={styles.container}>
      <div className={styles.line} />
        {caption && <p>{caption}</p>}
      <div className={styles.line} />

    </div>

  )
}
