import { GearUser } from 'types';
import { NextBackgroundImage } from 'components/NextImage';
import { NextLink } from 'components/NextLink';
import { USER_PHOTO_PLACEHOLDER_LINK } from 'consts';

import styles from './GearCard.module.scss';

export type ProductOwnerInfoProps = {
  user: GearUser;
};

export const GearOwnerInfo = ({ user }: ProductOwnerInfoProps) => {
  return (
    <div className={styles.productOwnerInfo}>
      <NextLink href={`/users/${user.username}`}>
        <NextBackgroundImage
          src={user.photo ? user.photo : USER_PHOTO_PLACEHOLDER_LINK}
          customClass={styles.ownerImage}
          alt={user.full_name}
        />
      </NextLink>
      <p>{user.full_name}</p>
    </div>
  );
};
