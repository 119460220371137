import { ChangeEvent, useState } from 'react';
import Image from 'next/legacy/image';

import cx from 'classnames';
import styles from './GearFilterPanel.module.scss';

export type FilterListProps = {
  labelField?: string;
  idField?: string;
  options: any[];
  onChange: (value: any) => void;
  searchBy?: string;
  searchId?: string;
  single?: boolean;
  value?: any;
  withSearch?: boolean;
};

export const FilterList = ({
  searchId,
  idField = 'slug',
  labelField = 'title',
  onChange,
  options = [],
  searchBy = 'items',
  single = false,
  value,
  withSearch = false,
}: FilterListProps) => {
  const [searchVal, setSearchVal] = useState<string>('');

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.currentTarget.value);
  };

  const handleSelect = (optionValue: string | number) => {
    if (single) {
      onChange(value === optionValue ? null : optionValue);
      return;
    }

    const index = value.indexOf(optionValue);

    if (index < 0) {
      onChange([...value, optionValue]);
    } else {
      const newValue = value.slice();
      newValue.splice(index, 1);
      onChange(newValue);
    }
  };

  const renderList = () => {
    const searched = searchVal.toLowerCase();

    return options.map((option) =>
      withSearch && searched && option[labelField].toLowerCase().indexOf(searched) < 0 ? null : (
        <div
          key={option[idField]}
          className={cx(styles.searchableListItem, {
            [styles.checked]: single ? value === option[idField] : value.indexOf(option[idField]) >= 0,
          })}
          onClick={() => handleSelect(option[idField])}
        >
          <div className={styles.listItemCheckbox}></div>
          <p>
            {option[labelField]}
            {typeof option.count !== 'undefined' && <span>{`(${option.count})`}</span>}
          </p>
        </div>
      ),
    );
  };

  return (
    <div className={styles.searchableListFilter}>
      {withSearch && (
        <div className={styles.searchableInputWrapper}>
          <div className={styles.searchableInputIcon}>
            <Image alt="Image" src="/icons/system/MagnifyingGlassSoft.svg" width={24} height={24} />
          </div>
          <input
            id={searchId}
            type="text"
            className={styles.searchableInput}
            placeholder={`Search by ${searchBy}`}
            value={searchVal}
            onChange={handleSearch}
          />
        </div>
      )}
      <div className={styles.searchableList}>{renderList()}</div>
    </div>
  );
};
