import { ChangeEvent, useState, useEffect } from 'react';

import styles from './GearFilterPanel.module.scss';

type PriceValues = {
  price_min?: number | '';
  price_max?: number | '';
};

export type PriceFilterProps = {
  values: PriceValues;
  onChange: (values: Object) => void;
};

export const PriceFilter = ({ values, onChange }: PriceFilterProps) => {
  const [priceValues, setPriceValues] = useState<PriceValues>(values);

  useEffect(() => {
    setPriceValues({
      price_min: values.price_min || '',
      price_max: values.price_max || '',
    });
  }, [values]);

  const handleSend = () => {
    onChange(priceValues || {});
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    setPriceValues({
      ...priceValues,
      [field]: e.target.value,
    });
  };

  const handleMinPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (priceValues.price_max && +priceValues.price_max < +e.target.value) {
      e.target.value = String(priceValues.price_max);
    }
    handleChange(e, 'price_min');
  };

  const handleMaxPriceChange = (e: ChangeEvent<HTMLInputElement>, isBlur?: boolean) => {
    if (isBlur && e.target.value && priceValues.price_min && +priceValues.price_min > +e.target.value) {
      e.target.value = String(priceValues.price_min);
    }
    handleChange(e, 'price_max');
  };

  return (
    <div className={styles.priceFilter}>
      <div className={styles.priceInputWrapper}>
        <input
          type="number"
          className={styles.priceInput}
          value={priceValues.price_min ? priceValues.price_min : ''}
          onChange={(e) => handleMinPriceChange(e)}
        />
        <p className={styles.priceInputPlaceholder}>From</p>
        <span className={styles.priceInputCurrency}>$</span>
      </div>
      <div className={styles.priceInputWrapper}>
        <input
          type="number"
          className={styles.priceInput}
          value={priceValues.price_max ? priceValues.price_max : ''}
          onChange={(e) => handleMaxPriceChange(e)}
          onBlur={(e) => handleMaxPriceChange(e, true)}
        />
        <p className={styles.priceInputPlaceholder}>To</p>
        <span className={styles.priceInputCurrency}>$</span>
      </div>
      <div className={styles.priceInputWrapper} onClick={handleSend}>
        <div className={styles.priceInputButton}>
          <p>Ok</p>
        </div>
      </div>
    </div>
  );
};
