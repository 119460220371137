import { Dispatch, MouseEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/legacy/image';
import { AllGearFilters, GearFilters, GearListFilters, QueryParams } from 'types';
import {DEAL_TYPE_OPTIONS, PRODUCT_CONDITIONS_LIST, PRODUCT_CONDITIONS_MAP} from 'consts';
import { getGearFilters } from 'api';
import { Button } from 'components/Button';
import { formatQueryFilters } from 'utils/formatters';
import { AppliedFilters } from './AppliedFilters';
import { FilterWrapper } from './FilterWrapper';
import { CategoriesFilter } from './CategoriesFilter';
import { FilterList } from './FilterList';
import { SizesFilter } from './SizesFilter';
import { ColorsFilter } from './ColorsFilter';
import { PriceFilter } from './PriceFilter';
import { LocationFilter } from './LocationFilter';
import { MarketsFilter } from './MarketsFilter';

import cx from 'classnames';
import styles from './GearFilterPanel.module.scss';
import {GearTypeList} from "./GearTypeList";

export type GearFiltersProps = {
  filters?: GearFilters;
  setFilterQueryParams: Dispatch<QueryParams>;
  presetFilters?: QueryParams;
  filterFetcher?: (query: QueryParams) => Promise<GearFilters>;
  isHome?: boolean;
};

const shipping = [
  { id: 'free', title: 'Free' },
  { id: 'discount', title: 'Discount & Free' },
];

const conditions = PRODUCT_CONDITIONS_LIST.map((condition) => ({
  id: condition,
  title: PRODUCT_CONDITIONS_MAP[condition],
}));

const statuses = [
  { id: 'all', title: 'All gear' },
  { id: 'active', title: 'Available gear' },
  { id: 'sold', title: 'Sold gear' },
];

export const additionalFilters = {
  type: DEAL_TYPE_OPTIONS,
  shipping,
  conditions,
};

export const GearFilterPanel = ({
  filters,
  setFilterQueryParams,
  presetFilters = {},
  filterFetcher = getGearFilters,
  isHome,
}: GearFiltersProps) => {
  const router = useRouter();
  const [opened, setOpened] = useState<any>({});
  const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<GearListFilters>({ status: 'active', ...presetFilters });
  const [allFilters, setAllFilters] = useState({ ...(filters || ({} as AllGearFilters)), ...additionalFilters });
  const mounted = useRef(false); // component mounted

  useLayoutEffect(() => {
    const queryFilters = formatQueryFilters(router.query as Record<string, string | string[]>);
    setAppliedFilters({ ...appliedFilters, ...queryFilters });
  }, [router.query]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateFilters = async () => {
    const updateFiltersResponse = await filterFetcher(appliedFilters);
    setAllFilters({ ...updateFiltersResponse, ...additionalFilters });
  };

  useEffect(() => {
    if (!mounted.current) {
      return;
    }

    setFilterQueryParams(appliedFilters);
    updateFilters().then();
  }, [appliedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // observe component mounted
    mounted.current = true;
  }, []);

  const handleApplyFilters = (addedFilters: Object) => {
    setAppliedFilters((previous) => {
      Object.entries(addedFilters).forEach((filterKeyValue) => {
        if (!filterKeyValue[1]) {
          // @ts-ignore
          delete previous[filterKeyValue[0]]
        }
      })
      return {
        ...previous,
        ...addedFilters,
      }
    });
  };

  const handleApplyFilter = (value: any, filter: string) => {
    if (value === null || value === undefined) {
      setAppliedFilters((previous) => {
        return Object.entries(previous || []).reduce((acc, entry) => {
          if (entry[0] !== filter) {
            // @ts-ignore
            acc[entry[0]] = entry[1];
          }
          return acc;
        }, {});
      });
    } else {
      setAppliedFilters((previous) => ({
        ...previous,
        [filter]: value,
      }));
    }
  };

  const handleCollapsed = useCallback(
    (name: string) => {
      setOpened({
        ...opened,
        [name]: !opened[name],
      });
    },
    [opened],
  );

  const toggleShowMobileFilters = () => {
    setShowMobileFilters((shown) => !shown);
  };

  const handleClickBackdrop = (e: MouseEvent<HTMLDivElement>) => {
    // @ts-ignore
    if (e.target.getAttribute('id') === 'filtersBackdrop' && showMobileFilters) {
      toggleShowMobileFilters();
    }
  };


  const [sidebarTop, setSidebarTop] = useState(600);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const sidebarEl = document?.querySelector('#filtersBackdrop')?.getBoundingClientRect();
    if(sidebarEl)
     setSidebarTop(sidebarEl.top + window.scrollY);
  }, []);

  useEffect(() => {
    if (!sidebarTop) return;

    window.addEventListener('scroll', checkIsSticky);
    return () => {
      window.removeEventListener('scroll', checkIsSticky);
    };
  }, [sidebarTop]);

  const checkIsSticky = () => {
    const scrollTop = window.scrollY;
    if (scrollTop >= sidebarTop - 20) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.filtersButton}>
        <Button
          caption="Show Filters"
          buttonType="white"
          buttonHeight="low"
          iconSrc="/icons/media/FadersHorizontalDark.svg"
          className={styles.filtersButtonButton}
          action={toggleShowMobileFilters}
        />
      </div>

      <div
        className={cx(styles.backdrop, {
          [styles.show]: showMobileFilters,
          [styles.sticky]: isSticky,
        })}
        id="filtersBackdrop"
        onClick={handleClickBackdrop}
      >
        <div className={styles.wrapper}>
          <div className={styles.modalHeader}>
            <p className={styles.modalHeaderText}>Filters</p>
            <div className={styles.modalHeaderIcon}>
              <Image
                alt="Close"
                width={24}
                height={24}
                src="/icons/development/XDark.svg"
                onClick={toggleShowMobileFilters}
              />
            </div>
          </div>
          <AppliedFilters allFilters={allFilters} appliedFilters={appliedFilters} onRemove={setAppliedFilters} isHome={isHome} />
          <FilterWrapper title="Markets" isCollapsed={!opened.markets} onCollapsed={() => handleCollapsed('markets')}>
            <MarketsFilter markets={allFilters.markets} appliedFilters={appliedFilters} onApply={setAppliedFilters} />
          </FilterWrapper>
          {!!allFilters.categories?.length && (
            <FilterWrapper
              title="Categories"
              isCollapsed={!opened.categories}
              onCollapsed={() => handleCollapsed('categories')}
            >
              <CategoriesFilter
                appliedFilters={appliedFilters}
                categories={allFilters.categories}
                highPriorityCategories={allFilters.high_priority_categories}
                onApply={setAppliedFilters}
              />
            </FilterWrapper>
          )}
          <FilterWrapper title="Brands" isCollapsed={!opened.brands} onCollapsed={() => handleCollapsed('brands')}>
            <FilterList
              options={allFilters.brands}
              value={appliedFilters.brands || []}
              withSearch
              searchBy="brands"
              searchId="filters-brand-search"
              onChange={(selected) => handleApplyFilter(selected, 'brands')}
            />
          </FilterWrapper>
          {!!allFilters.sizes?.length && (
            <FilterWrapper title="Sizes" isCollapsed={!opened.sizes} onCollapsed={() => handleCollapsed('sizes')}>
              <SizesFilter
                sizes={allFilters.sizes}
                value={appliedFilters.sizes || []}
                onChange={(selected) => handleApplyFilter(selected, 'sizes')}
              />
            </FilterWrapper>
          )}
          <FilterWrapper title="Colors" isCollapsed={!opened.colors} onCollapsed={() => handleCollapsed('colors')}>
            <ColorsFilter
              colors={allFilters.colors}
              value={appliedFilters.colors || []}
              onChange={(selected) => handleApplyFilter(selected, 'colors')}
            />
          </FilterWrapper>
          <FilterWrapper title="Price" isCollapsed={!opened.price} onCollapsed={() => handleCollapsed('price')}>
            <PriceFilter
              values={{ price_min: appliedFilters.price_min, price_max: appliedFilters.price_max }}
              onChange={handleApplyFilters}
            />
          </FilterWrapper>
          {/*<FilterWrapper*/}
          {/*  title="Deal Type"*/}
          {/*  isCollapsed={!opened.deal_type}*/}
          {/*  onCollapsed={() => handleCollapsed('deal_type')}*/}
          {/*>*/}
          {/*  <GearTypeList*/}
          {/*    value={appliedFilters.type}*/}
          {/*    onChange={(val) => handleApplyFilter(val, 'type')}*/}
          {/*  />*/}
          {/*</FilterWrapper>*/}
          <FilterWrapper
            title="Conditions"
            isCollapsed={!opened.conditions}
            onCollapsed={() => handleCollapsed('conditions')}
          >
            <FilterList
              options={conditions}
              value={appliedFilters.conditions || []}
              idField="id"
              onChange={(val) => handleApplyFilter(val, 'conditions')}
            />
          </FilterWrapper>
          <FilterWrapper
            title="Activity"
            isCollapsed={!opened.activity}
            onCollapsed={() => handleCollapsed('activity')}
          >
            <FilterList
              options={allFilters.activities}
              value={appliedFilters.activities || []}
              withSearch
              searchBy="activities"
              searchId="filters-activities-search"
              onChange={(selected) => handleApplyFilter(selected, 'activities')}
            />
          </FilterWrapper>
          <FilterWrapper
            title="Location"
            isCollapsed={!opened.location}
            onCollapsed={() => handleCollapsed('location')}
          >
            <LocationFilter country={appliedFilters.country} state={appliedFilters.state} city={appliedFilters.city} onChange={handleApplyFilters} />
          </FilterWrapper>
          <FilterWrapper
            title="Availability"
            isCollapsed={!opened.gearStatus}
            onCollapsed={() => handleCollapsed('gearStatus')}
          >
            <FilterList
              options={statuses}
              value={appliedFilters.status}
              idField="id"
              single
              onChange={(val) => handleApplyFilter(val, 'status')}
            />
          </FilterWrapper>
        </div>
      </div>
    </div>
  );
};
