import { useEffect, useRef, useState } from 'react';

import styles from './LazyLoader.module.scss';

export type LazyLoaderProps = {
  callback?: () => any;
  children?: HTMLElement | Element | any;
  throttle?: number;
  offset?: number;
  isActive?: boolean;
};

export const LazyLoader = ({ isActive = true, throttle = 300, callback, offset = 300, children }: LazyLoaderProps) => {
  const loaderRef = useRef<HTMLDivElement>(null);
  const shouldWaitRef = useRef<boolean>(false);
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    if (children && isVisible() && !showChildren) {
      setShowChildren(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = () => {
    if (isActive && !shouldWaitRef.current && isVisible()) {
      shouldWaitRef.current = true;
      callback && callback();
      children && !showChildren && setShowChildren(true);
      window.setTimeout(() => (shouldWaitRef.current = false), throttle);
    }
  };

  const isVisible = () => {
    const currentTop = loaderRef.current?.getBoundingClientRect().top;
    return currentTop && currentTop < window.innerHeight + offset;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={loaderRef} className={styles.container}>
      {!!children && showChildren && children}
    </div>
  );
};
